import parse from "html-react-parser"
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import React from 'react'
import BannerLanding from '../components/BannerLanding'
import CurrentTravels from '../components/CurrentTravels'
import Layout from '../components/layout'

export default function Post({ data: { post } }) {
  return (
    <Layout>
      <Seo post={post} />

      <BannerLanding
        image={
          post.featuredImage.node.localFile.childImageSharp.gatsbyImageData
        }
        accent={post.appearance.accent}
        title={post.title}
        description={post.excerpt}
      />

      <main id='main' className='spotlights'>{parse(post.content)}</main>

      <CurrentTravels />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    post: wpPost(id: {eq: $id}) {
      appearance {
        accent
      }
      content
      excerpt
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      title
    }
  }
`
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

const BannerLanding = props => (
  <section id="banner" className={`Banner Banner--style-${props.accent}`}>
    <div className="Banner-Image">
      <GatsbyImage
        image={props.image}
        objectFit="cover"
        objectPosition="50% 50%"
        alt="Titelbild" />
    </div>
    <div className="inner">
      <header className="major">
        <h1 dangerouslySetInnerHTML={{ __html: props.title }} />
      </header>
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: props.description,
        }}
      />
    </div>
  </section>
)

export default BannerLanding
